import {
  Box,
  Button,
  MonetizationOnOutlinedIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  enerbitColors,
  formatterPeso,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";

import BookmarkAddedOutlined from "@mui/icons-material/BookmarkAddedOutlined";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getInvoiceComponents,
  getMemoComponents,
} from "../../features/actions/actions";
import { onDocumentSelectedChanged } from "../../features/invoice/invoice";
import { Document } from "../../models/DocumentsGrouped";
import { AppDispatch } from "../../store/store";
import { openServiceTypeSpecificDialog } from "../../utils/openServiceTypeSpecificDialog";
export type TableInvoicesProps = {
  documents: Document[];
  openDialogInformation: () => void;
};

export const TableInvoices = ({
  documents,
  openDialogInformation,
}: TableInvoicesProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const getDocumentLogo = (document: Document) => {
    if (document.document_type == "invoice") {
      return <MonetizationOnOutlinedIcon sx={{ color: "white" }} />;
    }
    return <BookmarkAddedOutlined sx={{ color: "white" }} />;
  };

  const getDocumentBackGroundColor = (document: Document) => {
    if (document.document_type == "debit_note") {
      return enerbitColors.error.main;
    }
    if (document.document_type == "credit_note") {
      return enerbitColors.success.main;
    }
    return enerbitColors.primary.main;
  };

  const getDocumentTextColor = (document: Document) => {
    if (document.document_type == "debit_note") {
      return enerbitColors.error.main;
    }
    if (document.document_type == "credit_note") {
      return enerbitColors.success.main;
    }
    return enerbitColors.neutral[700];
  };

  return (
    <TableContainer>
      <Table
        className="Table-general"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Tipo de servicio</TableCell>
            <TableCell align="left">Fecha de vencimiento</TableCell>
            <TableCell align="left">Fecha de emisión</TableCell>
            <TableCell align="left">Valor</TableCell>
            <TableCell align="center">Detalle</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow
              key={document.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {document.id}
              </TableCell>
              <TableCell align="left">
                <Box display="flex" sx={{ gap: "8px", alignItems: "center" }}>
                  <Box
                    className="container-icon-table"
                    sx={{
                      backgroundColor: getDocumentBackGroundColor(document),
                    }}
                  >
                    {getDocumentLogo(document)}
                  </Box>
                  {serviceNames[document.service_type_name]
                    ? serviceNames[document.service_type_name]
                    : thirdPartyServiceNames[document.service_type_name]
                    ? thirdPartyServiceNames[document.service_type_name]
                    : null}
                </Box>
              </TableCell>
              <TableCell align="left">
                {moment(document.due_at).format("DD MMMM YYYY")}
              </TableCell>
              <TableCell align="left">
                {moment(document.issued_at).format("DD MMMM YYYY")}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    color: getDocumentTextColor(document),
                  }}
                >
                  {formatterPeso.format(document.payable_amount)}
                </span>
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (document.document_type === "invoice") {
                      dispatch(getInvoiceComponents({ id: document.id }));
                    } else {
                      dispatch(getMemoComponents({ id: document.id }));
                    }
                    openServiceTypeSpecificDialog({
                      serviceTypeName: document.service_type_name,
                      invoiceId: document.id,
                      documentType: document.document_type,
                      openDialogInformation: openDialogInformation,
                    });
                    dispatch(onDocumentSelectedChanged(document));
                  }}
                >
                  Ver detalle
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
