import { shouldShowInvoiceModalConceptForServiceType } from "@enerbit/base/common/helpers/invoiceModal";
import {
	mountedDisassembleAppsDetailMemos,
	mountedDisassembleAppsEssBillingDetail,
} from "../helpers/spa/spa.helpers";

type openDialogInformation = () => void;

export const openServiceTypeSpecificDialog = ({
	serviceTypeName,
	invoiceId,
	documentType,
	openDialogInformation,
}: {
	serviceTypeName: string;
	invoiceId: string;
	documentType: string;
	openDialogInformation: openDialogInformation;
}) => {
	console.log({ documentType, serviceTypeName });
	if (!serviceTypeName) {
		console.error("Nombre del tipo de servicio no proporcionado.");
		return;
	}
	if (
		shouldShowInvoiceModalConceptForServiceType(serviceTypeName) &&
		documentType === "invoice"
	) {
		return mountedDisassembleAppsEssBillingDetail(serviceTypeName, invoiceId);
	} else if (
		documentType != "invoice" &&
		serviceTypeName != "billerbit-street-light" &&
		serviceTypeName != "billerbit-security" &&
		serviceTypeName != "electric-meter-purchase"
	) {
		return mountedDisassembleAppsDetailMemos({
			service_type_name: serviceTypeName,
			invoice_memo_id: invoiceId,
		});
	} else {
		return openDialogInformation();
	}
};
