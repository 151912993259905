import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export type GetServiceAccountRelationships = {
  user_id?: string;
  service_account_id?: string;
  rol_name?: string;
  page: number;
  size: number;
};

export const getServiceAccountRelationships = createAsyncThunk(
  "invoices/getServiceAccountRelationships",
  async ({
    user_id,
    page,
    size,
    rol_name,
    service_account_id,
  }: GetServiceAccountRelationships) => {
    {
      const res = await api.get("/accounts/service-accounts-relationships/", {
        params: { user_id, page, size, rol_name, service_account_id },
      });
      return res.data;
    }
  }
);
