import * as singleSpa from "single-spa";

const mountedAppSingleSpaEssBillingDetail = (
  serviceTypeName: string,
  invoiceId: string
) => {
  singleSpa.registerApplication(
    "app-ess-billing-detail",
    (): Promise<singleSpa.LifeCycles> =>
      System.import("@enerBit/ess-billing-detail"),
    (location) => location.pathname.startsWith("/"),
    { serviceTypeName, invoiceId }
  );
};

export const mountedDisassembleAppsEssBillingDetail = async (
  serviceTypeName: string,
  invoiceId: string
) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-ess-billing-detail");

  if (appNamesAccess) {
    singleSpa.unregisterApplication("app-ess-billing-detail").then(async () => {
      await mountedAppSingleSpaEssBillingDetail(serviceTypeName, invoiceId);
    });
  } else {
    await mountedAppSingleSpaEssBillingDetail(serviceTypeName, invoiceId);
  }
};

const mountedAppSingleSpaDetailMemos = ({
  serviceTypeName,
  invoiceMemoId,
}: {
  serviceTypeName: string;
  invoiceMemoId: string;
}) => {
  singleSpa.registerApplication(
    "app-detail-memos",
    (): Promise<singleSpa.LifeCycles> => System.import("@enerBit/detail-memos"),
    (location) => location.pathname.startsWith("/"),
    { serviceTypeName, invoiceMemoId }
  );
};

export const mountedDisassembleAppsDetailMemos = async ({
  service_type_name,
  invoice_memo_id,
}: {
  service_type_name: string;
  invoice_memo_id: string;
}) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-detail-memos");

  if (appNamesAccess) {
    singleSpa.unregisterApplication("app-detail-memos").then(async () => {
      await mountedAppSingleSpaDetailMemos({
        serviceTypeName: service_type_name,
        invoiceMemoId: invoice_memo_id,
      });
    });
  } else {
    await mountedAppSingleSpaDetailMemos({
      serviceTypeName: service_type_name,
      invoiceMemoId: invoice_memo_id,
    });
  }
};
