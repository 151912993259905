import { BoltIcon, Box, formatterPeso, moment } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onComponentsSelectedCleaned,
  onDocumentSelectedChanged,
} from "../../features/invoice/invoice";
import { StateStorage } from "../../models/invoiceState";
import { AppDispatch } from "../../store/store";
import InvoiceInfo from "./InvoiceInfo";
import MemosInfo from "./MemosInfo";

export const DetailForm = () => {
  const { documentSelected } = useSelector(
    (state: StateStorage) => state.invoiceState
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return () => {
      dispatch(onDocumentSelectedChanged(null));
      dispatch(onComponentsSelectedCleaned());
    };
  }, []);

  return (
    <Box>
      <Box className="Box-detail-invoice">
        <Box sx={{ marginBottom: "18px" }}>
          <Box sx={{ fontSize: "18px" }}>Detalle de tu factura en:</Box>
          <Box sx={{ fontSize: "18px" }}>
            Número de id: {documentSelected?.id}
          </Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="center">
            <Box className="Icon-bolt-invoice">
              <BoltIcon />
            </Box>
          </Box>
          <Box sx={{ fontSize: "12px", marginTop: "10px" }}>Total a pagar</Box>
          <Box sx={{ fontSize: "34px", fontWeight: "700" }}>
            {formatterPeso.format(documentSelected?.payable_amount ?? 0)}
          </Box>
          <Box sx={{ fontSize: "14px" }}>
            Fecha de vencimiento:{" "}
            {moment(documentSelected?.due_at ?? 0).format("YYYY-MM-DD")}
          </Box>
        </Box>
      </Box>
      {/* Facturas */}
      {documentSelected?.document_type == "invoice" ? (
        <InvoiceInfo />
      ) : (
        <MemosInfo />
      )}
    </Box>
  );
};
