import {
  AdapterMoment,
  Box,
  CircularProgress,
  DatePicker,
  FormGroup,
  Grid,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  TextField,
  Typography,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getServiceAccountRelationships } from "../../features/actions/actions";
import {
  onCleanServiceAccounts,
  onFiltersChanged,
} from "../../features/invoice/invoice";
import { ServiceAccountRelationship } from "../../models/ServiceAccount";
import { StateStorage } from "../../models/invoiceState";
import { AppDispatch } from "../../store/store";

export type FormFilterProps = {
  user_id?: string;
};

export const FormFilter = ({ user_id }: FormFilterProps) => {
  const { filters, serviceAccounts, isLoadingServiceAccounts } = useSelector(
    (state: StateStorage) => state.invoiceState
  );

  const [consecutive, setConsecutive] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useParams();

  const _getServiceAccountLabel = (
    serviceAccount: ServiceAccountRelationship
  ): string => {
    if (serviceAccount.personalizations.length > 0) {
      return serviceAccount.personalizations[0].alias;
    }
    return serviceAccount.service_account_id.split("-")[0];
  };

  useEffect(() => {
    setConsecutive(filters.consecutive?.toString() ?? null);
  }, []);

  useEffect(() => {
    if ((serviceAccounts?.items.length ?? 0) == 0) {
      dispatch(
        getServiceAccountRelationships({
          page: 0,
          size: 300,
          user_id: user_id ?? userId,
        })
      );
    }
    return () => {
      dispatch(onCleanServiceAccounts());
    };
  }, []);

  const _consecutiveError = (): string | null => {
    if (!consecutive) {
      return null;
    }
    const consecutiveNumber = Number(consecutive);
    if (consecutiveNumber > 0) {
      return null;
    }
    return "El valor debe de ser un valor númerico";
  };

  return (
    <Box className="content-dialog-filter-form">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormGroup className="w-100">
            <InputLabel shrink className="Input-label">
              Seleccionar grupo de servicio
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius "
              id="qualityGroupId"
              name="qualityGroupId"
              variant="outlined"
              value={filters.serviceAccountId ?? ""}
              onChange={(e) => {
                const serviceAccountId = e.target.value;
                if (serviceAccountId.length > 0) {
                  dispatch(
                    onFiltersChanged({
                      ...filters,
                      serviceAccountId,
                    })
                  );
                }
              }}
            >
              {isLoadingServiceAccounts ? (
                <CircularProgress />
              ) : (
                serviceAccounts?.items.map((serviceAccount) => {
                  return (
                    <MenuItem
                      key={serviceAccount.service_account_id}
                      value={serviceAccount.service_account_id}
                    >
                      {_getServiceAccountLabel(serviceAccount)}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup className="w-100">
            <InputLabel shrink className="Input-label">
              Seleccionar mes
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={["year", "month"]}
                openTo="month"
                maxDate={moment()}
                format="MMMM YYYY"
                onChange={(value, _) => {
                  if (value) {
                    dispatch(
                      onFiltersChanged({
                        ...filters,
                        month: value.format("MMMM YYYY"),
                      })
                    );
                  }
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    className: "TextField-without-border-radius",
                  },
                }}
                value={moment(filters.month)}
              />
            </LocalizationProvider>
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup className="w-100">
            <InputLabel shrink className="Input-label">
              CUFE
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius "
              id="cufe"
              name="cufe"
              variant="outlined"
              value={filters.cufe ?? ""}
              onChange={(e) => {
                dispatch(
                  onFiltersChanged({
                    ...filters,
                    cufe: e.target.value,
                  })
                );
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup className="w-100">
            <InputLabel shrink className="Input-label">
              Consecutivo
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius "
              id="consecutive"
              name="consecutive"
              variant="outlined"
              value={consecutive ?? ""}
              error={_consecutiveError() != null}
              helperText={_consecutiveError()}
              onChange={(e) => {
                setConsecutive(e.target.value);
                const consecutive = Number(e.target.value);
                if (consecutive > 0) {
                  dispatch(
                    onFiltersChanged({
                      ...filters,
                      consecutive,
                    })
                  );
                } else {
                  dispatch(
                    onFiltersChanged({
                      ...filters,
                      consecutive: undefined,
                    })
                  );
                }
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};
