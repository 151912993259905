import { Box, Typography, enerbitColors } from "@enerbit/base";

export enum CardStatusEnum {
  pending,
  overdue,
  payed,
}

export type CardStatusProps = {
  status: CardStatusEnum;
};

export const CardStatus = ({ status }: CardStatusProps) => {
  let color: string | undefined;
  let backgroundColor: string | undefined;
  let label: string;
  switch (status) {
    case CardStatusEnum.pending:
      color = enerbitColors.warning.main;
      backgroundColor = enerbitColors.warning[100];
      label = "Pendiente";
      break;
    case CardStatusEnum.overdue:
      color = enerbitColors.error.main;
      backgroundColor = enerbitColors.error[100];
      label = "Vencida";
      break;
    case CardStatusEnum.payed:
      color = enerbitColors.success.main;
      backgroundColor = enerbitColors.success[100];
      label = "Pagada";
      break;
  }
  return (
    <Box className="card-status" sx={{ backgroundColor }}>
      <Typography variant="subtitle2" color={color}>
        {label}
      </Typography>
    </Box>
  );
};
