import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (id: string) => {
    let response = await api.get(`users/app-users/${id}`);
    return response.data;
  }
);
