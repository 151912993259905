import { Button, DeleteOutlineIcon, SearchIcon } from "@enerbit/base";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsGrouped,
  getTotalDue,
} from "../../features/actions/actions";
import { onFiltersChanged } from "../../features/invoice/invoice";
import { StateStorage } from "../../models/invoiceState";
import { AppDispatch } from "../../store/store";

export type ButtonsFiltersProps = {
  userId: string;
  handleClose?: () => void;
  searchPayed?: boolean;
  searchUnPayed?: boolean;
};

export const ButtonsFilters = ({
  userId,
  handleClose,
  searchPayed = true,
  searchUnPayed = true,
}: ButtonsFiltersProps) => {
  const { filters } = useSelector((state: StateStorage) => state.invoiceState);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<SearchIcon />}
        onClick={() => {
          let invoice_period = moment().format("YYYY-MM");
          if (filters.month) {
            invoice_period = moment(filters.month).format("YYYY-MM");
          }
          dispatch(
            getTotalDue({
              user_id: userId,
              service_account_id: filters.serviceAccountId,
            })
          );
          if (searchUnPayed) {
            dispatch(
              getDocumentsGrouped({
                invoice_period,
                is_paid: false,
                reset_data: true,
                user_id: userId,
                service_account_id: filters.serviceAccountId,
                consecutive: filters.consecutive?.toString(),
                stamp_code: filters.cufe,
              })
            );
          }
          if (searchPayed) {
            dispatch(
              getDocumentsGrouped({
                invoice_period,
                is_paid: true,
                reset_data: true,
                user_id: userId,
                service_account_id: filters.serviceAccountId,
                consecutive: filters.consecutive?.toString(),
                stamp_code: filters.cufe,
              })
            );
          }
          if (handleClose) {
            handleClose();
          }
        }}
      >
        Filtrar
      </Button>
      <Button
        color="primary"
        startIcon={<DeleteOutlineIcon />}
        onClick={() => {
          dispatch(
            getTotalDue({
              user_id: userId,
            })
          );
          dispatch(onFiltersChanged({ month: null }));
        }}
      >
        Borrar
      </Button>
    </>
  );
};
