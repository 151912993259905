import {
  AdapterMoment,
  Box,
  Button,
  CardPdf,
  CloseIcon,
  DatePicker,
  Dialog,
  DragZone,
  FormGroup,
  IconButton,
  InputLabel,
  LocalizationProvider,
  TextField,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransaction,
  uploadPaymentReceipt,
} from "../../../features/actions/payments/payments.actions";
import { resetTransaction } from "../../../features/invoice/invoice";
import { StateStorage } from "../../../models/invoiceState";
import { AppDispatch } from "../../../store/store";
import { ConfirmationDialog } from "./ConfirmationDialog";

export type ApplyPaymentFormDialogProps = {
  open: boolean;
  handleClose: () => void;
  userId: string;
};

export const ApplyPaymentFormDialog = ({
  handleClose,
  open,
  userId,
}: ApplyPaymentFormDialogProps) => {
  const [date, setDate] = useState(moment());
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [documentId, setDocumentId] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const {
    fileResponse,
    isUploadingFile,
    isApplyingPayments,
    documentsGroupedUnPayed,
    isTransactionCreated,
  } = useSelector((state: StateStorage) => state.invoiceState);

  const getUnPayedDocuments = () => {
    const groups = documentsGroupedUnPayed.document_groups.filter(
      (documents) => documents.isSelected
    );
    const docsRequest = [];
    for (let i = 0; i < groups.length; i++) {
      const documents = groups[i].documents;
      for (let j = 0; j < documents.length; j++) {
        docsRequest.push({
          document_id: documents[j].id,
          document_type:
            documents[j].document_type != "invoice" ? "Memo" : "Invoice",
        });
      }
    }
    return docsRequest;
  };

  useEffect(() => {
    setFile(null);
  }, []);

  useEffect(() => {
    if (fileResponse && !isApplyingPayments) {
      dispatch(
        createTransaction({
          accounting_date: date.toDate(),
          accounting_document_id: documentId,
          documents: getUnPayedDocuments(),
          pay_total_due: false,
          payer_id: userId,
          payment_receipt: fileResponse.blob_url,
        })
      );
    }

    return () => {};
  }, [fileResponse]);

  useEffect(() => {
    if (isTransactionCreated) {
      handleCloseConfirmationDialog();
      handleClose();
      dispatch(resetTransaction());
    }
  }, [isTransactionCreated]);

  const isEnabledButton = (): boolean => {
    return file != null && documentId != "" && date != null;
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box padding="32px" className="content-dialog-filter">
          <Box className="content-dialog-quit-button">
            <IconButton
              aria-label="Cerrar"
              sx={{ width: "24px", height: "24px" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="box-apply-payment-dialog">
            <Typography
              variant="h5"
              color={enerbitColors.primary.main}
              fontWeight="bold"
            >
              Comprobante
            </Typography>
            <FormGroup className="w-100">
              <InputLabel shrink className="Input-label">
                ID documento contable
              </InputLabel>
              <TextField
                className="TextField-without-border-radius"
                variant="outlined"
                onChange={(e) => {
                  setDocumentId(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="w-100">
              <InputLabel shrink className="Input-label">
                Fecha de registro contable
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  maxDate={moment()}
                  onChange={(value, _) => {
                    if (value) {
                      setDate(value);
                    }
                  }}
                  slotProps={{
                    textField: {
                      className: "TextField-without-border-radius",
                      variant: "outlined",
                    },
                  }}
                  value={date}
                />
              </LocalizationProvider>
            </FormGroup>
            <DragZone
              onDrop={(files) => {
                if (files.length > 0) {
                  setFile(files[0]);
                }
              }}
              color="secondary"
              typeFile="img,pdf"
            />
            {file && (
              <CardPdf
                typeFile={file.name.split(".").pop()}
                isSuccess={true}
                bgColor="error"
                isClearFile={true}
                fileInfo={{
                  name: file.name,
                  size: file.size,
                }}
                progress={100}
                onClearFile={() => {
                  setFile(null);
                }}
              />
            )}
            <Box
              className="w-100"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenConfirmationDialog(true);
                }}
                disabled={!isEnabledButton()}
              >
                Aplicar pago
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <ConfirmationDialog
        onAccept={() => {
          if (file) {
            dispatch(uploadPaymentReceipt(file));
          }
        }}
        handleClose={handleCloseConfirmationDialog}
        open={openConfirmationDialog}
      />
    </>
  );
};
