import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  Breadcrumbs,
  CircularProgress,
  IconButton,
  Link,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import React from "react";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../models/invoiceState";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  history.back();
}

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" onClick={handleClick}>
    Bitoria
  </Link>,
  <Typography key="3" color={enerbitColors.neutral[900]} fontWeight="bold">
    Facturas pagadas
  </Typography>,
];

export const HeaderInvoicesPayed = () => {
  const { isLoadingUser, appUser } = useSelector(
    (state: StateStorage) => state.invoiceState
  );

  return (
    <Box className="header-all-invoices">
      <Breadcrumbs separator={"›"} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <Box className="box-title-all-invoices">
        <IconButton
          color="primary"
          onClick={() => {
            history.back();
          }}
        >
          <ArrowCircleLeftOutlinedIcon className="Icon-back" />
        </IconButton>
        <Box>
          <Typography
            variant="h5"
            fontWeight="bold"
            color={enerbitColors.primary.main}
          >
            Facturas pagadas
          </Typography>
          {isLoadingUser ? (
            <CircularProgress />
          ) : (
            <Typography variant="subtitle1" color={enerbitColors.primary.main}>
              {`${appUser?.pii.names} ${appUser?.pii.last_names}`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
