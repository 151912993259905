import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const uploadPaymentReceipt = createAsyncThunk(
  "payments/uploadPaymentReceipt",
  async (file: File) => {
    let response = await api.post(
      "payments/payment-receipts",
      {
        file: file,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response.data;
  }
);

export type CreateTransactionProps = {
  documents: Document[];
  payment_receipt: string;
  payer_id: string;
  pay_total_due: boolean;
  accounting_document_id: string;
  accounting_date: Date;
};

export type Document = {
  document_id: string;
  document_type: string;
};

export const createTransaction = createAsyncThunk(
  "payments/createTransaction",
  async (data: CreateTransactionProps) => {
    let response = await api.post("payments/transactions", data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }
);
