import { initEnviroment } from "@enerbit/base";
import Routers from "./router/Routers";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export type InvoicesMicroProps = {
  document_group_id?: string;
  service_account_id?: string;
  user_id: string;
  is_paid?: boolean;
};

export default function Root({
  user_id,
  document_group_id,
  is_paid,
  service_account_id,
}: InvoicesMicroProps) {
  return (
    <Routers
      user_id={user_id}
      document_group_id={document_group_id}
      is_paid={is_paid}
      service_account_id={service_account_id}
    />
  );
}
