import { PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { InvoicesPayedPage } from "../pages/InvoicesPayed/InvoicesPayedPage";
import SectionInvoices from "../pages/SectionInvoices/SectionInvoices";
import { InvoicesMicroProps } from "../root.component";
import { store } from "../store/store";

export default function Routers({
  user_id,
  document_group_id,
  is_paid,
  service_account_id,
}: InvoicesMicroProps) {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <HashRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <SectionInvoices
                    user_id={user_id}
                    document_group_id={document_group_id}
                    is_paid={is_paid}
                    service_account_id={service_account_id}
                  />
                }
              />
              <Route
                path="/invoices/:userId/detail"
                element={<InvoicesPayedPage />}
              />
            </Routes>
          </HashRouter>
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
