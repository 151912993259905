import { Box, Typography, enerbitColors } from "@enerbit/base";

export type CardTitleAccordionProps = {
  title: string;
  subtitle: string;
  suffixElement?: JSX.Element;
};

export const CardTitleAccordion = ({
  title,
  subtitle,
  suffixElement,
}: CardTitleAccordionProps) => {
  return (
    <Box className="card-title-accordion">
      <Typography
        variant="subtitle2"
        color={enerbitColors.neutral[700]}
        fontWeight="bold"
      >
        {title}
        {suffixElement && suffixElement}
      </Typography>
      <Typography
        fontSize={12}
        color={enerbitColors.neutral[300]}
        fontWeight="bold"
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
