import {
  Box,
  Grid,
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
  PaidOutlinedIcon,
  formatterPeso,
  translateDetailBillingName,
  translateServiceName,
} from "@enerbit/base";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StateStorage } from "../../models/invoiceState";

const InvoiceInfo = () => {
  const [seeInvoice, setInvoice] = useState(true);
  const { documentSelected, documentComponents, isLoadingInvoiceComponents } =
    useSelector((state: StateStorage) => state.invoiceState);

  return (
    <Box className="Box-detail-invoice-repeat" sx={{ mt: "10px" }}>
      <Box className="Box-detail-invoice-border">
        <Box
          className="Grid-container-main Box-flex-invoices"
          onClick={() => setInvoice(!seeInvoice)}
        >
          <Box className="Icon-detail-invoice">
            <PaidOutlinedIcon />
          </Box>
          <Box sx={{ fontWeight: "700", width: "100%" }}>
            <Box sx={{ color: "var(--color-neutral500)" }}>
              Factura{" "}
              {translateServiceName(documentSelected?.service_type_name || "")}
            </Box>
            <Box sx={{ color: "var(--color-neutral900)" }}>
              {formatterPeso.format(documentSelected?.payable_amount || 0)}
            </Box>
          </Box>
          <Box className="Icon-arrow-accordeon">
            {seeInvoice ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </Box>
        </Box>
        {seeInvoice &&
          documentComponents != null &&
          documentComponents.components.map((value, index) => {
            return (
              <Grid
                key={index}
                container
                spacing={2}
                className="Grid-container-main"
              >
                <Grid item xs={6}>
                  <Box>
                    {translateDetailBillingName(value.name) || value.name}
                  </Box>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "end" }}>
                  <Box>
                    {formatterPeso.format(value.amount + value.tax_amount)}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </Box>
  );
};

export default InvoiceInfo;
