import { Box, CloseIcon, IconButton } from "@enerbit/base";
import Dialog from "@mui/material/Dialog";
import { ButtonsFilters } from "./ButtonsFilters";
import { FormFilter } from "./FormFilter";

export type FiltersDialogProps = {
  open: boolean;
  handleClose: () => void;
  userId: string;
};

export const FiltersDialog = ({
  open,
  handleClose,
  userId,
}: FiltersDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <Box padding="32px" className="content-dialog-filter">
        <Box className="content-dialog-quit-button">
          <IconButton
            aria-label="Cerrar"
            sx={{ width: "24px", height: "24px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FormFilter user_id={userId} />
        <Box className="content-buttons-filter">
          <ButtonsFilters userId={userId} handleClose={handleClose} />
        </Box>
      </Box>
    </Dialog>
  );
};
