import { Box } from "@enerbit/base";
import { useParams } from "react-router-dom";
import { ButtonsFilters } from "../../components/ButtonsFilters";
import { FormFilter } from "../../components/FormFilter";

export const FiltersInvoicePayed = () => {
  const { userId } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "16px",
        alignItems: "flex-end",
      }}
    >
      <FormFilter />
      <ButtonsFilters userId={userId ?? ""} searchUnPayed={false} />
    </Box>
  );
};
