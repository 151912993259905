import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export type FilterOptions = {
  serviceAccountId?: string;
  month: string | null;
  cufe?: string;
  consecutive?: number;
};

export const donwloadInvoice = createAsyncThunk(
  "invoices/donwloadInvoice",
  async (invoiceId: string) => {
    let response = await api.get(`/billing/invoices/${invoiceId}/document/`, {
      params: {},
      responseType: "arraybuffer",
    });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${invoiceId}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
  }
);

export const downloadMemo = createAsyncThunk(
  "billing/downloadMemo",
  async (memoId: string) => {
    const res = await api.get(`/billing/memos/${memoId}/document`, {
      responseType: "arraybuffer",
    });
    const file = new Blob([res.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${memoId}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
    return res.data;
  }
);

export const downloadInvoiceGroup = createAsyncThunk(
  "invoices/downloadInvoiceGroup",
  async (invoiceIds: string[], thunkAPI: any) => {
    let path = "/billing/invoice-documents-merge/";
    for (var i = 0; i < invoiceIds.length; i++) {
      if (i == 0) {
        path += "?invoices_ids=" + invoiceIds[i];
      } else {
        path += "&invoices_ids=" + invoiceIds[i];
      }
    }
    let response = await api.get(path, {
      responseType: "arraybuffer",
    });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${new Date().getTime()}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
  }
);

export type GetDocumentsGroupedProps = {
  invoice_period: string;
  user_id: string;
  is_paid: boolean;
  reset_data: boolean;
  document_group_id?: string;
  service_account_id?: string;
  stamp_code?: string;
  consecutive?: string;
};

export const getDocumentsGrouped = createAsyncThunk(
  "invoices/getDocumentsGrouped",
  async ({
    invoice_period,
    is_paid,
    user_id,
    document_group_id,
    service_account_id,
    stamp_code,
    consecutive,
  }: GetDocumentsGroupedProps) => {
    {
      const res = await api.get("/billing/documents-grouped", {
        params: {
          invoice_period,
          is_paid,
          user_id,
          document_group_id,
          service_account_id,
          stamp_code,
          consecutive,
        },
      });

      return res.data;
    }
  }
);

export type GetTotalDueProps = {
  user_id: string;
  document_group_id?: string;
  service_account_id?: string;
};

export const getTotalDue = createAsyncThunk(
  "invoices/totalDue",
  async ({
    user_id,
    document_group_id,
    service_account_id,
  }: GetTotalDueProps) => {
    {
      const res = await api.get("/billing/total-due", {
        params: {
          user_id,
          document_group_id,
          service_account_id,
        },
      });

      return res.data;
    }
  }
);

export type GetInvoiceComponents = {
  id: string;
};

export const getInvoiceComponents = createAsyncThunk(
  "invoices/getInvoiceComponents",
  async ({ id }: GetInvoiceComponents) => {
    {
      const res = await api.get(`/billing/api/v1/invoices/${id}/components`);
      return res.data;
    }
  }
);

export const getMemoComponents = createAsyncThunk(
  "invoices/getMemoComponents",
  async ({ id }: GetInvoiceComponents) => {
    {
      const res = await api.get(
        `/billing/api/v1/invoice-memos/${id}/components`
      );
      return res.data;
    }
  }
);
