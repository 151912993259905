import {
  Box,
  Button,
  Checkbox,
  DownloadOutlinedIcon,
  Grid,
  Typography,
  enerbitColors,
  formatterPeso,
  serviceNames,
} from "@enerbit/base";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadInvoiceGroup } from "../../features/actions/actions";
import {
  onDocumentGroupCheckChanged,
  onDocumentGroupExpandedChanged,
} from "../../features/invoice/invoice";
import { DocumentGrouped } from "../../models/DocumentsGrouped";
import { AppDispatch } from "../../store/store";
import { CardStatus, CardStatusEnum } from "./CardStatus";
import { CardTitleAccordion } from "./CardTitleAccordion";
import { TableInvoices } from "./TableInvoices";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
    borderRadius: "12px 12px 0px 0px",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: enerbitColors.neutral[100],
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  borderRadius: "12px",
  padding: "16px",
  "& .MuiAccordionSummary-content": {
    margin: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: enerbitColors.neutral[100],
  borderTop: `1px solid ${enerbitColors.neutral[300]}`,
  borderRadius: "0px 0px 12px 12px",
}));

export type CustomizedAccordionProps = {
  documentGrouped: DocumentGrouped;
  openDialogInformation: () => void;
  canCheck?: boolean;
};

export const CustomizedAccordion = ({
  documentGrouped,
  openDialogInformation,
  canCheck = true,
}: CustomizedAccordionProps) => {
  const [showSuffixByDebitNotes, setShowSuffixByDebitNotes] = useState<
    boolean | null
  >(null);
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = () => {
    dispatch(onDocumentGroupExpandedChanged(documentGrouped));
  };

  const getLabel = (name: string) => {
    switch (name) {
      case "energy":
        return "Energía";

      default:
        return name;
    }
  };

  const getAddress = () => {
    let address = "";
    if (documentGrouped.estate) {
      if (documentGrouped.estate.personalization) {
        address += `${documentGrouped.estate.personalization} - `;
      }
      address += documentGrouped.estate.address;
    }
    return address;
  };

  const getStatus = (): CardStatusEnum => {
    if (
      documentGrouped.documents.every(
        (document) => document.payment_date != null
      )
    ) {
      return CardStatusEnum.payed;
    }
    const documents = documentGrouped.documents;
    if (documents.length > 0) {
      let minDueAt = moment(documents[0].due_at);
      for (let i = 1; i < documents.length; i++) {
        const newDueAt = moment(documents[i].due_at);
        if (minDueAt.isAfter(newDueAt)) {
          minDueAt = newDueAt;
        }
      }
      if (minDueAt.isBefore(moment())) {
        return CardStatusEnum.overdue;
      }
    }
    return CardStatusEnum.pending;
  };

  const hasMoreDebitNotes = () => {
    const documents = documentGrouped.documents;
    let total = 0;
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].document_type != "invoice") {
        total += documents[i].payable_amount;
      }
    }
    if (total > 0) {
      return true;
    }
    if (total == 0) {
      return null;
    }
    return false;
  };

  const getSuffixElement = () => {
    if (showSuffixByDebitNotes == null) {
      return <></>;
    }
    return (
      <Typography
        display="inline"
        variant="body2"
        color={
          showSuffixByDebitNotes
            ? enerbitColors.error.main
            : enerbitColors.success.main
        }
      >
        *
      </Typography>
    );
  };

  useEffect(() => {
    setShowSuffixByDebitNotes(hasMoreDebitNotes());
  }, []);

  return (
    <Accordion expanded={documentGrouped.isExpanded} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container spacing={2} className="content-header-accordion">
          <Grid item xs={3}>
            <Box className="content-checkbox-accordion">
              {canCheck && (
                <Checkbox
                  checked={documentGrouped.isSelected}
                  onChange={(_, checked) => {
                    dispatch(
                      onDocumentGroupCheckChanged({
                        checked,
                        documentGroup: documentGrouped,
                      })
                    );
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
              )}

              <CardTitleAccordion
                title={
                  serviceNames[documentGrouped.name] ??
                  getLabel(documentGrouped.name) ??
                  documentGrouped.name
                }
                subtitle="Grupo de pago"
                suffixElement={getSuffixElement()}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <CardTitleAccordion title={getAddress()} subtitle="Dirección" />
          </Grid>
          <Grid item xs={2}>
            <CardTitleAccordion
              title={moment(documentGrouped.invoice_period).format("MMMM YYYY")}
              subtitle="Mes"
            />
          </Grid>
          <Grid item xs={2}>
            <CardTitleAccordion
              title={formatterPeso.format(documentGrouped.payable_amount)}
              subtitle="Total factura"
              suffixElement={getSuffixElement()}
            />
          </Grid>
          <Grid item xs={1}>
            <CardStatus status={getStatus()} />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              endIcon={<DownloadOutlinedIcon />}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  downloadInvoiceGroup(
                    documentGrouped.documents.map((document) => document.id)
                  )
                );
              }}
            >
              PDF
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableInvoices
          documents={documentGrouped.documents}
          openDialogInformation={openDialogInformation}
        />
      </AccordionDetails>
    </Accordion>
  );
};
